<template>
    <div id="courseTeaching">

        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">精品案例</span>>
                <span style="color: #999999;cursor: pointer;" @click="detailsClick">精品案例详情</span>>
                <span style="color: #999999;cursor: pointer;" @click="artisanClick">思政亮点</span>>
                <span>思政亮点详情</span>
            </div>

            <div style="width:100%;display: flex;justify-content: space-between;">
                <div style="width:100%;background:#fff; float: left;">
                    <div
                        style="width:100%;height:50px;background:#fff;color:#76C8F6;text-align:center;line-height:40px;font-size: 18px;font-weight:600;border-bottom:1px solid #F7F8FA;margin-bottom:10px;">
                        {{ caseName }}思政亮点</div>
                    <div>
                        <p style="font-weight:600;text-align:center">{{ artisan.title }}</p>
                        <p style="font-size:12px;text-align:center;padding-top:20px;">日期：{{ artisan.insertTime }}</p>
                        <p class="contentStyle" ref="contentRef" style="padding:20px;line-height:25px;font-size:14px;"
                            v-html="artisan.content">
                        </p>
                    </div>
                </div>
                <div class="rightBox">
                    <div
                        style="width: 100%;height: 50px;line-height: 50px;text-align: center;border-bottom: 1px solid #F7F8FA;font-weight: bold;color: #333333;">
                        目录
                    </div>
                    <div style="width:95%;height:60px;background:#F7F8FA;margin:10px;cursor: pointer;"
                        v-for="(item, index) in conentList" :key="index" @click="muluClick(item)">
                        <!-- 1咨询 2人物  3访谈   4视频 -->
                        <p v-if="item.label == 1"
                            style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                            资讯</p>
                        <p v-if="item.label == 2"
                            style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                            人物</p>
                        <p v-if="item.label == 3"
                            style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                            访谈</p>
                        <p v-if="item.label == 4"
                            style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                            视频</p>
                        <!-- <span style="display:inline-block;width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;float:left">资讯</span> -->
                        <div
                            style="font-size:14px;text-align:center;margin-left:20px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;"
                            v-html="item.title"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed, watch, onUpdated, onBeforeUpdate, nextTick, } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import { backUrl } from "@/js/util"
import * as echarts from 'echarts';
import { } from "@/js/homeListApi.js"
import { baseUrl } from '@/js/util.js'
import { ElMessage } from 'element-plus';
export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const state = reactive({
            baseUrl: baseUrl,
            userId: getStorage('userId'),
            shwoCenter: 1,
            artisan: getStorage('artisan'),
            caseName: route.query.caseName,
            conentList: getStorage('conentList'),
            contentRef: null
        })
        const methods = {


            indexClick() {
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick() {
                router.push('/home/boutiqueCases');
            },
            detailsClick() {
                router.push('/home/casesDetail');
            },
            artisanClick() {
                router.push('/home/artisan');
            },
            muluClick(item) {
                state.artisan = item
                if (item.gccType == 2) {
                    window.open(item.content, "_blank")
                }
            },
            muluTwoClick() {
                state.shwoCenter = 2;
            },
        }
        onMounted(() => {
            console.log(state.artisan);
            nextTick(() => {
                const ele = state.contentRef.querySelectorAll('img')
                ele.forEach((element) => {
                    element.style.width = '-webkit-fill-available';
                });
            })
        })
        return {
            ...toRefs(state),
            ...methods,
        };
    }
}
</script>

<style  lang="scss" scoped>
#courseTeaching {
    height: 100%;
    position: relative;
    background: #F7F8FA;

    .box {
        width: 66.5%;
        height: 100%;
        margin: 0 auto;

        :deep .el-input__inner {
            border-radius: 50px !important;
            border: none;
        }

        .contentStyle {
            ::deep(img) {
                width: -webkit-fill-available !important;
            }
        }

        .breadCrumbs {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 12px;

        }

        .rightBox {
            width: 354px;
            height: auto;
            background: #fff;
            float: right;
        }
    }
}
</style>